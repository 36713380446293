<template>
  <fw-layout mobile-ready>
    <template #main-content>
      <div>
        <fw-heading size="h1" :counter="enrollments ? Object.values(enrollments).flat().length : 0"
          >Certificados</fw-heading
        >
      </div>
      <div class="my-5">
        <LoadingPlaceholder v-if="loading" size="lg" />

        <div v-for="year in years" v-else :key="year">
          <fw-panel
            :counter="enrollments[year] ? enrollments[year].length : 0"
            boxed="xs"
            class="mb-5 -mt-2"
            :title="year == 'unknown' ? 'Ano não definido' : year"
          >
            <RecycleScroller v-slot="{ item }" key-field="key" :items="enrollments[year]" :item-size="78" :buffer="50">
              <div class="flex gap-3 bg-white px-4 py-2 rounded-lg mb-1 hover:bg-gray-300 hover:bg-opacity-30">
                <div>
                  <fw-icon-award class="w-6 h-6 flex-shrink-0" />
                </div>
                <div class="flex-1 flex flex-col gap-1">
                  <div v-if="item.course?.title">
                    <v-clamp autoresize :max-lines="1" class="font-medium text-base leading-5">
                      {{ item.course.title }}
                    </v-clamp>
                  </div>
                  <div v-if="item.edition" class="flex gap-3 text-sm text-gray-500">
                    <div>
                      <v-clamp autoresize :max-lines="1" class="font-semibold">
                        {{ item.edition.title }}
                      </v-clamp>
                    </div>
                    <div>
                      {{ item.edition.start_date | formatDate }} -
                      {{ item.edition.end_date | formatDate }}
                    </div>
                  </div>
                  <div v-if="item.file" class="text-sm text-primary flex items-center gap-5 md:gap-10">
                    <a href="#" class="font-semibold" @click.prevent="downloadFile(item.file)"
                      >Descarregar certificado</a
                    >
                  </div>
                </div>
              </div>
            </RecycleScroller>
          </fw-panel>
        </div>

        <fw-panel-info v-if="!loading && isObjectEmpty(enrollments)" centered empty class="p-3">
          Sem certificados
        </fw-panel-info>
      </div>
      <!-- <div v-if="totalPages > 1" class="px-4">
        <BlockPagination
          v-if="totalPages > 1 && !loading"
          :per-page="pageLimit"
          :total="totalResults"
          :total-pages="totalPages"
          :current.sync="currentPage"
          @page-changed="pageChanged"
        />
      </div> -->
    </template>
  </fw-layout>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller'
// import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    RecycleScroller,
    // BlockPagination,
    LoadingPlaceholder,
  },

  data() {
    return {
      loading: true,

      // Data
      enrollments: {},
      years: [],

      // Pagination
      totalResults: 0,
      totalPages: 0,
      currentPage: 1,
      pageLimit: 50,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
  },

  mounted() {
    this.getUrlParams()
    this.getUserEnrollments()
  },

  methods: {
    getUrlParams() {
      if (this.$route.query.p) {
        this.page = parseInt(this.$route.query.p)
      }
    },

    setUrlParams() {
      let query = {}
      query['p'] = this.currentPage
      this.$router.push({ path: this.$route.path, query: query })
    },

    async getUserEnrollments() {
      const query = {
        limit: this.pageLimit,
        page: this.currentPage,
      }

      await utils.tryAndCatch(
        this,
        async () => {
          const result = await this.api.getUserEnrollments(query)
          // this.totalResults = result.pagination?.total_items
          // this.totalPages = result.pagination?.total_pages
          // this.currentPage = result.pagination?.current_page
          let keys = Object.keys(result.enrollments)
          keys.sort((a, b) => b - a)

          this.years = keys
          this.enrollments = result.enrollments
        },
        // Finally
        () => {
          this.loading = false
        }
      )
    },

    pageChanged(page) {
      console.log('pageChanged to >> ', page)
      if (page) this.currentPage = page
      this.setUrlParams()
      this.getUserEnrollments()
    },

    downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },

    isObjectEmpty(obj) {
      return utils.isObjectEmpty(obj)
    },
  },
}
</script>
